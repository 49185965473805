
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400&display=swap&text=0123456789JQKA%E2%99%A0%E2%99%A5%E2%99%A3%E2%99%A6');
@import url(https://fonts.googleapis.com/css?family=PT+Sans|PT+Sans+Narrow|Raleway|Signika);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #eef1f6;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23c8c7f7' fill-opacity='0.30'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

h1 {
    font-family: "Signika", sans-serif;
}

:root {
  /* font-size: 30px; */

  width: 100vw;
  max-width: 100%;
  height: 100vh;

  --cardScale: 1.0;

  --red-suit-color: #e44145;
  --black-suit-color: #252525;

  --card-background-color: #fafafa;
  --disabled-card-background-color: #e6e6e6;

  --modal-z-index: 10;
  --score-z-index: 5;

  --card-stack-z-index: 0;
  --card-stack-hover-card-z-index: 10;

  --player-name-indicator-z-index: 2;
  --card-container-z-index: 0;
  --hand-card-z-index: 0;
  --hand-card-hover-z-index: 10;

  --active-border-color: #173249dd;
  --active-shadow-color: #eed18f5c;
  --active-shadow: 0px -30px 50px 2px var(--active-shadow-color);

  --card-font-family: 'Noto Sans SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --card-font-weight: 400;
}
